import { defineComponent } from 'vue';
import { makeAssociated, EventRecordLog } from '@/apis/index';
export default defineComponent({
    name: 'AssociatedOrganizationEntry',
    data() {
        return {
            state: '',
            companyname: '',
            errmsg: '',
        };
    },
    async created() {
        // 信任通道埋点 受邀访问
        EventRecordLog('', '', 'AssociatedOrganization.Visit', '', true);
    },
    methods: {
        async onSureAssociated() {
            const enginecode = this.$route.query.enginecode || '';
            const guid = this.$route.query.guid || '';
            const res = await makeAssociated({ enginecode, guid });
            this.companyname = (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.companyname) || '';
            this.errmsg = res.errmsg || res.ErrorMessage || (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.errormsg);
            if (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.errorcode === 0) {
                this.state = 'isSuccessAssociated';
                // 信任通道埋点 互通成功
                EventRecordLog('', '', 'AssociatedOrganization.Associate.Success');
            }
            else {
                const errcode = res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.errorcode;
                // 信任通道埋点 互通失败
                EventRecordLog('', '', 'AssociatedOrganization.Associate.Fail');
                switch (errcode) {
                    case 110003: // 非管理员
                        this.state = 'isNonAdminister';
                        break;
                    case 110004: // 已连接
                        this.state = 'isAssociated';
                        break;
                    case 110005: // 非付费
                        this.state = 'isNonPaid';
                        break;
                    default: // 其他
                        this.state = 'isOthers';
                        break;
                }
            }
        },
        goToAssociatedOrganization() {
            window.location.href = `/pc/system-integration.html#/version-info?frompath=associatedOrganization`;
        },
        goToH3yun() {
            window.location.href = `${window.location.origin}/home.html?t=${new Date().getTime()}`;
        }
    }
});
